import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Close: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="22px" height="22px" viewBox="0 0 22 22" className={clss}>
            <g stroke="non" strokeWidth="1" fill="none">
                <g transform="translate(-1102.000000, -843.000000)" stroke={color} strokeWidth="1.5">
                    <g transform="translate(734.000000, 812.000000)">
                        <g transform="translate(368.000000, 31.000000)">
                            <g transform="translate(2.000000, 1.000000)">
                                <path d="M0.411337612,0.370478339 L17.4087951,17.4496544 M17.5886624,0.46084435 L0.591204887,17.5400204"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Close;
